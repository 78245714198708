



























import { ProfileModel } from '@/models/profile-model'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class Avatar extends Vue {
  @Prop({ default: false }) square!: boolean
  @Prop({ default: true }) requireWallet!: boolean
  @Prop({ default: true }) requireLogin!: boolean
  @Prop({}) profile!: any
  @Prop({ default: '60' }) size!: any
  @Prop({ default: true }) shouldOpenProfile!: boolean

  getProfileId(profile: ProfileModel) {
    return profile.unique_id
  }

  get defaultAvatar() {
    const defaultAvatarIndex = this.profile?.defaultAvatarIndex
    return defaultAvatarIndex != undefined
      ? `${process.env.VUE_APP_API_ENDPOINT}/uploads/profile-avatars/profile-avatar-${defaultAvatarIndex}.png`
      : undefined
  }
}
